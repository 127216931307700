import classNames from 'classnames';
import Link from 'next/link';
import type { FC } from 'react';
import { useState } from 'react';

import LogoSymbol from '@/assets/logo-black.svg';
import Button from '@/components/Button';
import SubscribeButton from '@/components/SubscribeButton';
import { useScrollLock } from '@/hooks/useScrollLock';

import ConditionalActionButton from './ConditionalActionButton';
import MindPalCoHamburger from './Hamburger';
import type { MenuType } from './Header';

type Props = {
  menu: MenuType;
  theme?: 'light' | 'dark';
};
const MobileHeader: FC<Props> = ({ menu, theme = 'light' }) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  useScrollLock(isHamburgerOpen);

  return (
    <div className="flex w-full justify-end py-4 lg:hidden">
      <Link href="/">
        <div
          className={classNames(
            'absolute left-1/2 top-1/2 text-neutral-1000 -translate-x-1/2 -translate-y-1/2',
            {
              '!text-neutral-0': theme === 'dark',
            }
          )}
        >
          <LogoSymbol />
        </div>
      </Link>
      <Button
        onClick={() => setIsHamburgerOpen(true)}
        prefixIcon="material-symbols-light:menu"
        variant="unstyled"
        iconClassName={classNames('text-white !text-2xl', {
          '!text-jb-neutral-1000': theme === 'light',
        })}
      />
      <MindPalCoHamburger
        menu={menu}
        isOpen={isHamburgerOpen}
        onClose={() => setIsHamburgerOpen(false)}
      >
        <ConditionalActionButton />
        <SubscribeButton className="w-full" />
      </MindPalCoHamburger>
    </div>
  );
};

export default MobileHeader;
