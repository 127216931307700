import { Icon } from '@iconify/react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '@/lib/utils';

const contentStyles = cva('', {
  variants: {
    variant: {
      recruiter:
        'rounded-2xl border border-[#885AFD55] bg-gradient-to-tr from-black/90 to-secondary-900/90 p-6',
      candidate:
        'rounded-2xl border border-jb-neutral-400 bg-jb-neutral-100 p-6 shadow-lg',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'unstyled',
  },
});

const linkStyle = cva('flex', {
  variants: {
    variant: {
      recruiter:
        'flex flex-row items-center gap-4 rounded-lg border-2 border-primary-900 bg-neutral-100/5 p-4 backdrop-blur-lg hover:border-jb-primary-400',
      candidate:
        'flex-row items-center gap-4 rounded-lg border-2 border-jb-neutral-400 p-4 hover:border-jb-neutral-600',
      text: 'w-fit whitespace-nowrap p-2',
    },
  },
  defaultVariants: {
    variant: 'text',
  },
});

const NavigationMenuIndicator = NavigationMenuPrimitive.Indicator;
const NavigationMenuItem = NavigationMenuPrimitive.Item;
const NavigationMenuSub = NavigationMenuPrimitive.Sub;

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={cn('w-full relative', className)}
    {...props}
  >
    {children}

    <NavigationMenuPrimitive.Viewport className="!absolute !top-full left-0 mt-4 w-full !duration-75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
  </NavigationMenuPrimitive.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={cn('flex flex-row gap-4', className)}
    {...props}
  />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex flex-row items-center group p-2 whitespace-nowrap',
      className
    )}
    {...props}
  >
    {children}
    <Icon
      icon="iconamoon:arrow-down-2-light"
      className="transition-all group-hover:rotate-180"
      fontSize={20}
    />
  </NavigationMenuPrimitive.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content> &
    VariantProps<typeof contentStyles>
>(({ className, variant, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={cn(contentStyles({ variant }), className)}
    {...props}
  />
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Link>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link> &
    VariantProps<typeof linkStyle>
>(({ className, variant, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Link
    ref={ref}
    className={cn(linkStyle({ variant }), className)}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Link>
));
NavigationMenuLink.displayName = NavigationMenuPrimitive.Link.displayName;

export {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuSub,
  NavigationMenuTrigger,
};
