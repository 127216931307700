import { useEffect } from 'react';

export const useScrollLock = (isScrollLocked: boolean) => {
  const preventDefault = (e: Event) => e.preventDefault();
  return useEffect(() => {
    const enableScroll = () => {
      document.body.style.overflow = 'auto';
      document.removeEventListener('touchmove', preventDefault);
    };

    const disableScroll = () => {
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', preventDefault, {
        passive: false,
      });
    };

    if (isScrollLocked) disableScroll();
    else enableScroll();
    return () => {
      enableScroll();
    };
  }, [isScrollLocked]);
};
