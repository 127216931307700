import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import LogoCVWhite from '@/assets/logo-mindpal.svg';
import SubscribeButton from '@/components/SubscribeButton';
import Text from '@/components/Text';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/components/ui/navigation-menu';
import { useHiddenHeader } from '@/hooks/useHiddenHeader';

import ConditionalActionButton from './ConditionalActionButton';
import MobileHeader from './MobileHeader';
import type { SubMenuType } from './SubmenuContent';
import SubmenuContent from './SubmenuContent';

export type MenuType = (
  | {
      name: string;
      href?: never;
      authOnly?: boolean;
      adminOnly?: boolean;
      submenu: SubMenuType[];
    }
  | {
      name: string;
      href: string;
      authOnly?: boolean;
      adminOnly?: boolean;
      submenu?: never;
    }
)[];

const candidateSubmenu: SubMenuType[] = [
  {
    name: 'Resume Checker',
    description: 'AI Resume Analyzer helps you fix resume mistakes',
    icon: 'material-symbols-light:document-search-outline',
    href: '/resume-checker',
  },
  {
    name: 'Bullet Points Generator',
    description: 'Create a powerful bullet points focused on impact',
    icon: 'material-symbols-light:lists-rounded',
    href: '/bullet-points',
  },
];

const recruiterSubmenu: SubMenuType[] = [
  {
    name: 'MindPal Recruiters',
    description: 'Overview of the Platform for Recruiter',
    icon: 'material-symbols-light:in-home-mode-outline',
    href: '/recruiter',
  },
  {
    name: 'Resume Generator',
    description: 'Anonymize and generate resumes for recitations',
    icon: 'material-symbols-light:fact-check-outline',
    href: '/resume',
  },
  {
    name: 'Job Matching',
    description: 'Find a perfect match for the job offer in seconds',
    icon: 'material-symbols-light:document-search-outline',
    href: '/job-matching',
  },
  {
    name: 'MindPal ATS',
    description: 'Boost hiring process with the AI- powered solutions',
    icon: 'material-symbols-light:data-loss-prevention-outline',
    href: '/ats',
  },
  {
    name: 'AI HR Boots',
    description: 'We offer to create a custom AI boots for your needs',
    icon: 'material-symbols-light:data-loss-prevention-outline',
    href: '/hr-bots',
  },
  {
    name: 'Recruiters Blog',
    description: 'Get newest informations form HR world',
    icon: 'material-symbols-light:local-library-outline',
    href: '/blog',
  },
  {
    name: 'Pricing',
    description: 'Annual, Yearly and custom plans for everyone',
    icon: 'material-symbols-light:credit-card-outline',
    href: '/dashboard/pricing',
  },
];

export const menu: MenuType = [
  { name: 'Home', href: '/' },
  {
    name: 'For Candidates',
    submenu: candidateSubmenu,
  },
  {
    name: 'For Recruiters',
    submenu: recruiterSubmenu,
  },
  { name: 'About Us', href: '/about-us' },
];

type Props = {
  theme?: 'dark' | 'light';
};
const Header: FC<Props> = ({ theme = 'dark' }) => {
  const { pathname } = useRouter();
  const { show, isOnTop } = useHiddenHeader();

  const pathAvailableMenu = menu.filter(({ name }) => {
    const recruiterOnlyPaths = recruiterSubmenu.map((item) => item.href);
    const candidateOnlyPaths = candidateSubmenu.map((item) => item.href);
    if (recruiterOnlyPaths.includes(pathname)) return name !== 'For Candidates';
    if (candidateOnlyPaths.includes(pathname)) return name !== 'For Recruiters';
    return true;
  });

  return (
    <div
      className={classNames(
        'fixed left-0 transition-all top-0 flex w-full items-center z-50 bg-transparent opacity-100',
        {
          '!bg-neutral-1000/40': !isOnTop,
          '!opacity-0 pointer-events-none': !show,
        }
      )}
    >
      <div
        className={classNames('hidden w-full px-24 py-10 lg:flex', {
          'text-neutral-1000': theme === 'light',
        })}
      >
        <NavigationMenu className="flex items-center justify-between">
          <Link href="/">
            <div className="w-40">
              <LogoCVWhite />
            </div>
          </Link>
          <div className="relative flex flex-row items-center gap-8">
            <NavigationMenuList>
              {pathAvailableMenu.map(({ name, href, submenu }) =>
                submenu ? (
                  <NavigationMenuItem key={name}>
                    <NavigationMenuTrigger>
                      <Text
                        className={classNames({
                          'font-semibold': submenu
                            .map((item) => item.href)
                            .includes(pathname),
                        })}
                      >
                        {name}
                      </Text>
                    </NavigationMenuTrigger>
                    <SubmenuContent submenu={submenu} menuName={name} />
                  </NavigationMenuItem>
                ) : (
                  <NavigationMenuItem key={name}>
                    <NavigationMenuLink asChild variant="text">
                      <Link href={href}>
                        <Text
                          className={classNames({
                            'font-semibold': pathname === href,
                          })}
                        >
                          {name}
                        </Text>
                      </Link>
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                )
              )}
            </NavigationMenuList>
            <SubscribeButton size="md" className="text-white" />
            <ConditionalActionButton />
          </div>
        </NavigationMenu>
      </div>
      <MobileHeader menu={pathAvailableMenu} theme={theme} />
    </div>
  );
};

export default Header;
