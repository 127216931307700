import { Icon } from '@iconify/react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';

import Text from '@/components/Text';
import {
  NavigationMenuContent,
  NavigationMenuLink,
} from '@/components/ui/navigation-menu';

export type SubMenuType = {
  name: string;
  description: string;
  icon: string;
  href: string;
};

const recruiterLightUrlsList = ['/', '/dashboard/pricing', '/about-us'];
const SubmenuContent = ({
  submenu,
  menuName,
}: PropsWithChildren<{
  submenu: SubMenuType[];
  menuName: string;
}>) => {
  const { pathname } = useRouter();
  const isRecruiter = menuName === 'For Recruiters';
  const isCandidate =
    menuName === 'For Candidates' || recruiterLightUrlsList.includes(pathname);

  return (
    <NavigationMenuContent
      variant={isCandidate ? 'candidate' : 'recruiter'}
      className="grid grid-cols-4 gap-2"
    >
      {submenu.map(({ name, description, icon, href }) => (
        <NavigationMenuLink
          asChild
          key={name}
          variant={isCandidate ? 'candidate' : 'recruiter'}
        >
          <Link
            href={href}
            className={classNames({
              'bg-jb-neutral-200': isCandidate && pathname === href,
              '!bg-primary-700/50': isRecruiter && pathname === href,
            })}
          >
            <div className="py-2">
              <Icon
                className={classNames({
                  'text-primary-700': isRecruiter,
                  'text-jb-secondary-500': isCandidate,
                })}
                icon={icon}
                fontSize={48}
              />
            </div>
            <div>
              <Text
                variant="jb-body-large"
                className={classNames({
                  'text-jb-neutral-1000': isCandidate,
                  'text-jb-neutral-100': isRecruiter,
                })}
              >
                {name}
              </Text>
              <Text
                variant="jb-body-medium"
                className={classNames({
                  'text-jb-neutral-700': isCandidate,
                  'text-jb-neutral-400': isRecruiter,
                })}
              >
                {description}
              </Text>
            </div>
          </Link>
        </NavigationMenuLink>
      ))}
    </NavigationMenuContent>
  );
};

export default SubmenuContent;
